import * as React from "react"
import {css} from "@emotion/react"
import {f} from "~/emotionStyles/function"

const ContentsFooter = () =>{

  return(
    <div css={wrap}>
      <p css={text}>Copyright ©Gekidan otaka-no-kaze All rights reserved.</p>
    </div>
  )
}


const wrap = css({
  width:'100%',
  padding: '30px 0',
})

const text = css({
  textAlign:'center',
  [f.pc()]: {
    fontSize:'14px',
    color:'#333',   
    lineHeight:'200%' 
  },
  [f.sp()]: {
    fontSize:'12px',
    color:'#333',
    lineHeight:'180%' 
  }
})




export default ContentsFooter