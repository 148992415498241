import * as React from "react"
import {css} from "@emotion/react"
import {f} from "~/emotionStyles/function"

import WorksList from "~/components/Molecules/index/worksList"

const worksArray = []
const worksList = [
    {
      "title":"父と暮せば",
      "number":"旗揚げ公演",
      "url":"/works/stage1/",
      "img":"/images/stage1/stage1.jpg"
    },
    {
      "title":"ちょっと愉快な授業報告",
      "number":"番外公演 Vol.1",
      "url":"/works/stage2/",
      "img":"/images/stage2/stage2.jpg"
    },
    {
      "title":"楽屋- 流れ去るものはやがてなつかしき -",
      "number":"第2回公演",
      "url":"/works/stage3/",
      "img":"/images/stage3/stage3.jpg"
    },
    {
      "title":"俺たちは、これで、うまく行っているほうじゃないかなぁ。",
      "number":"第3回公演",
      "url":"/works/stage4/",
      "img":"/images/stage4/stage4.jpg"
    },
    {
      "title":"どうして飲まないんだ?",
      "number":"第4回公演",
      "url":"/works/stage5/",
      "img":"/images/stage5/stage5.jpg"
    },
    {
      "title":"雨さへ降らなければね。",
      "number":"第5回公演",
      "url":"/works/stage6/",
      "img":"/images/stage6/stage6.jpg"
    },
    {
      "title":"話は明日にしましょ",
      "number":"第6回公演",
      "url":"/works/stage7/",
      "img":"/images/stage7/stage7.jpg"
    }
  ]

  function createWorksList (){
    for(let i = 0; i < worksList.length ; i++){
      worksArray.push(<div css={worksList} key={i} ><WorksList data={worksList[i]}/></div>)
    }
  }
  createWorksList()

const WorksItems = ()=>{
  return (
    <div css={contentsWrap}>
      <h3 css={contentsTitle}>過去公演</h3>
      <div css={list}>
        {worksArray}
      </div>
    </div>
  )
}

const contentsWrap = css({
  width:'100%',
  [f.pc()]: {
    paddingTop: f.vw(80),
  },
  [f.sp()]: {
    paddingTop: '50px',
  },
})

const contentsTitle = css({
  [f.pc()]: {
    fontSize:'32px',
    fontWeight:'bold',
    color:'#333',
    marginBottom:'35px'
  },
  [f.sp()]: {
    fontSize:'28px',
    fontWeight:'bold',
    color:'#333',
    marginBottom:'20px'
  }
})

const list = css({
  marginTop:"30px",
  display:'grid',
  gridTemplateColumns:'1fr 1fr',
  gap:'30px',
  [f.sp()]: {
    gap:'20px 15px',
  }
})
export default WorksItems