import * as React from "react"
import { Link } from "gatsby"
import {css} from "@emotion/react"
import {f} from "~/emotionStyles/function"

const WorksList = ({data}) =>{
  return(
    <li css={worksItem}>
      <div css={contentsImg}>
        <Link to={data.url}>
          <img src={data.img} alt={data.title} />
        </Link>
      </div>
      <span css={worksNum}>{data.number}</span>
      <h4 css={worksTitle}>{data.title}</h4>
    </li>
  )  
}

const worksItem = css({
  listStyle:'none',
})

const worksTitle = css({
  lineHeight:"1.5",
  [f.pc()]: {
    marginTop:'10px',
    fontSize:'20px',
    fontWeight:'bold',
    color:'#333',
  },
  [f.sp()]: {
    marginTop:'10px',
    fontSize:'18px',
    fontWeight:'bold',
    color:'#333',
  },
})

const worksNum = css({
  padding:'5px 10px',
  borderRadius:'10px',
  backgroundColor:'#74b9ff',
  fontSize:'14px',
  fontWeight:'bold',
  color:'#fff',
})

const contentsImg =css({
  width:'100%',
  marginBottom:'15px'
})


export default WorksList