import * as React from "react"
import PropTypes from "prop-types"
import {css} from "@emotion/react"
import {f} from "~/emotionStyles/function"

const Inner = ({children}) => {
  return (
    <>
      <div css={inner}>{children}</div>
    </>
  )
}

Inner.propTypes = {
  children: PropTypes.node.isRequired,
}

const inner = css({
  [f.pc()]: {
    maxWidth:'875px',
    width:f.vw(875),
    marginLeft: f.vw(80),
  },

  [f.sp()]: {
    width:'90%',
    margin:'0 auto'
  }
})

export default Inner