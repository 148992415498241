import * as React from "react"
import {css} from "@emotion/react"
import {f} from "~/emotionStyles/function"

const ContentsTextBlock = ({title,lead}) =>{
  const texts = lead.split(/(\n)/).map((item, index) => {
    return (
      <React.Fragment key={index}>
        { item.match(/\n/) ? <br /> : item }
      </React.Fragment>
    )
  })
  return(
    <div css={contentsWrap}>
      <h3 css={contentsTitle}>{title}</h3>
      <p css={contentsLead}>{texts}</p>
    </div>
  )  
}

const contentsWrap = css({
  width:'100%',
  [f.pc()]: {
    paddingTop: f.vw(80),
  },
  [f.sp()]: {
    paddingTop:'50px',
  },
})

const contentsTitle = css({
  [f.pc()]: {
    fontSize:'32px',
    fontWeight:'bold',
    color:'#333',
    marginBottom:'35px'
  },
  [f.sp()]: {
    fontSize:'24px',
    fontWeight:'bold',
    color:'#333',
    marginBottom:'20px'
  }
})

const contentsLead = css({
  [f.pc()]: {
    fontSize:'16px',
    color:'#333',   
    lineHeight:'200%' 
  },
  [f.sp()]: {
    fontSize:'16px',
    color:'#333',
    lineHeight:'200%' 
  }
})

export default ContentsTextBlock